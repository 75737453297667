import React from 'react'
import clsx from 'clsx'
import styled from '@emotion/styled'
import theme from '../../../theme'

const StyledCard = styled.div`
  padding: 48px;
  margin-bottom: 35px;
  border-radius: 7px;

  ${props =>
    props.dark && props.lightBorder && !props.hideBorder
      ? 'border: 1px solid #dedede;'
      : ''};

  ${props => {
    if (props.dark) {
      return `
        background-color: ${theme.palette.darkGrey};        
        h1, h2, p, a, label {
          color: #ffffff;
        }
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      `
    } else if (props.backgroundColor) {
      return `background-color: ${props.backgroundColor};`
    }

    return `
      background-color: #ffffff;
      ${props.hideBorder ? '' : 'border: 1px solid #dedede;'}    
      box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.03);
    `
  }}
`

const Card = ({ className, children, style, onClick, ...props }) => (
  <StyledCard
    className={clsx('card', className)}
    style={style}
    onClick={onClick}
    {...props}
  >
    {children}
  </StyledCard>
)

export default Card
