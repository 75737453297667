import React from 'react'
import styled from '@emotion/styled'
import theme from '../../theme'

const StyledSection = styled.div`
  padding: 60px 20px;
  width: 100%;
  ${props => {
    if (props.dark) {
      return `background-color: ${theme.palette.darkGrey};`
    } else if (props.backgroundColor) {
      return `background-color: ${props.backgroundColor};`
    }

    return ''
  }}

  .content {
    max-width: ${theme.measurements.maxContentWidth}px;
    margin: auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${props => {
        if (props.dark) {
          return `color: ${theme.palette.white};`
        }
      }}
    }

    h1,
    h2 {
      margin-bottom: 1rem;
    }

    p {
      ${props => {
        if (props.dark && !props.color) {
          return `color: ${theme.palette.grey}; !important;`
        } else if (props.color) {
          return `color: ${props.color} !important;`
        }

        return ''
      }}
    }

    h1:not(:first-of-type) {
      margin-top: 7rem;
    }
  }
`

const Section = ({ children, backgroundImage, style, ...props }) => (
  <StyledSection
    style={{
      backgroundImage: backgroundImage ? `url("${backgroundImage}")` : 'none',
      ...style,
    }}
    {...props}
  >
    <div className="content">{children}</div>
  </StyledSection>
)

export default Section
