import BaseLayout from '../components/layout/BaseLayout'
import Card from '../components/elements/cards/Card'
import React from 'react'
import Section from '../components/layout/Section'
import makeSeoTitle from '../lib/utils/makeSeoTitle'

const PrivacyPolicyPage = () => (
  <BaseLayout>
    <Section>
      <Card>
        <h1>Datenschutzerklärung</h1>
        <p>
          Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
          Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz
          „Daten“) im Rahmen der Erbringung unserer Leistungen sowie innerhalb
          unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
          Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser
          Social Media Profile auf (nachfolgend gemeinsam bezeichnet als
          „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten,
          wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
          Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO). <br />
          <br />
        </p>
        <h2 id="dsg-general-controller">Verantwortlicher</h2>
        <p>
          <span className="tsmcontroller">
            Tim Trietsch
            <br />
            Furtwänglerstraße 51
            <br />
            69121 Heidelberg <br />
            E-Mail: tim@eisdesigns.de
            <br />
            Link zum Impressum: https://eisdesigns.de/impressum
          </span>
        </p>
        <h2 id="dsg-general-datatype">Arten der verarbeiteten Daten</h2>
        <p>
          - Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).
          <br />
          - Kontaktdaten (z.B., E-Mail, Telefonnummern).
          <br />
          - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).
          <br />
          - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten).
          <br />- Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
          IP-Adressen).
        </p>
        <h2 id="dsg-general-datasubjects">Kategorien betroffener Personen</h2>
        <p>
          Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir
          die betroffenen Personen zusammenfassend auch als „Nutzer“).
          <br />
        </p>
        <h2 id="dsg-general-purpose">Zweck der Verarbeitung</h2>
        <p>
          - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
          Inhalte.
          <br />
          - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
          <br />
          - Sicherheitsmaßnahmen.
          <br />
          - Reichweitenmessung/Marketing
          <br />
          <span className="tsmcom"></span>
        </p>
        <h2 id="dsg-general-terms">Verwendete Begrifflichkeiten </h2>
        <p>
          „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person (im Folgenden
          „betroffene Person“) beziehen; als identifizierbar wird eine
          natürliche Person angesehen, die direkt oder indirekt, insbesondere
          mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
          Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie)
          oder zu einem oder mehreren besonderen Merkmalen identifiziert werden
          kann, die Ausdruck der physischen, physiologischen, genetischen,
          psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
          dieser natürlichen Person sind.
          <br />
          <br />
          „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren
          ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
          personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
          jeden Umgang mit Daten.
          <br />
          <br />
          „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer
          Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
          Informationen nicht mehr einer spezifischen betroffenen Person
          zugeordnet werden können, sofern diese zusätzlichen Informationen
          gesondert aufbewahrt werden und technischen und organisatorischen
          Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen
          Daten nicht einer identifizierten oder identifizierbaren natürlichen
          Person zugewiesen werden.
          <br />
          <br />
          „Profiling“ jede Art der automatisierten Verarbeitung
          personenbezogener Daten, die darin besteht, dass diese
          personenbezogenen Daten verwendet werden, um bestimmte persönliche
          Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
          insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche
          Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit,
          Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person
          zu analysieren oder vorherzusagen.
          <br />
          <br />
          Als „Verantwortlicher“ wird die natürliche oder juristische Person,
          Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten entscheidet, bezeichnet.
          <br />
          <br />
          „Auftragsverarbeiter“ eine natürliche oder juristische Person,
          Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im
          Auftrag des Verantwortlichen verarbeitet.
          <br />
        </p>
        <h2 id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h2>
        <p>
          Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
          unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich
          der Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt,
          sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt
          wird, Folgendes: <br />
          Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6
          Abs. 1 lit. a und Art. 7 DSGVO;
          <br />
          Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
          Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung
          von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;
          <br />
          Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
          rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;
          <br />
          Für den Fall, dass lebenswichtige Interessen der betroffenen Person
          oder einer anderen natürlichen Person eine Verarbeitung
          personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit.
          d DSGVO als Rechtsgrundlage.
          <br />
          Die Rechtsgrundlage für die erforderliche Verarbeitung zur Wahrnehmung
          einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
          öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde
          ist Art. 6 Abs. 1 lit. e DSGVO. <br />
          Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
          berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. <br />
          Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie
          erhoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4
          DSGVO. <br />
          Die Verarbeitung von besonderen Kategorien von Daten (entsprechend
          Art. 9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des Art. 9 Abs. 2
          DSGVO. <br />
        </p>
        <h2 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere
          des Risikos für die Rechte und Freiheiten natürlicher Personen,
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.
          <br />
          <br />
          Zu den Maßnahmen gehören insbesondere die Sicherung der
          Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
          Kontrolle des physischen Zugangs zu den Daten, als auch des sie
          betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
          Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
          eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung
          von Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner
          berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
          Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren,
          entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und
          durch datenschutzfreundliche Voreinstellungen.
          <br />
        </p>
        <h2 id="dsg-general-coprocessing">
          Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen
          und Dritten
        </h2>
        <p>
          Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
          Personen und Unternehmen (Auftragsverarbeitern, gemeinsam
          Verantwortlichen oder Dritten) offenbaren, sie an diese übermitteln
          oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf
          Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung
          der Daten an Dritte, wie an Zahlungsdienstleister, zur
          Vertragserfüllung erforderlich ist), Nutzer eingewilligt haben, eine
          rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer
          berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
          Webhostern, etc.). <br />
          <br />
          Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe
          offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt
          dies insbesondere zu administrativen Zwecken als berechtigtes
          Interesse und darüberhinausgehend auf einer den gesetzlichen Vorgaben
          entsprechenden Grundlage. <br />
        </p>
        <h2 id="dsg-general-thirdparty">Übermittlungen in Drittländer</h2>
        <p>
          Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
          Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
          Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme
          von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an
          andere Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es
          zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage
          Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf
          Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich
          ausdrücklicher Einwilligung oder vertraglich erforderlicher
          Übermittlung, verarbeiten oder lassen wir die Daten nur in
          Drittländern mit einem anerkannten Datenschutzniveau, zu denen die
          unter dem "Privacy-Shield" zertifizierten US-Verarbeiter gehören oder
          auf Grundlage besonderer Garantien, wie z.B. vertraglicher
          Verpflichtung durch sogenannte Standardschutzklauseln der
          EU-Kommission, dem Vorliegen von Zertifizierungen oder verbindlichen
          internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,{' '}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_de"
            target="blank"
          >
            Informationsseite der EU-Kommission
          </a>
          ).
        </p>
        <h2 id="dsg-general-rightssubject">Rechte der betroffenen Personen</h2>
        <p>
          Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
          betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
          sowie auf weitere Informationen und Kopie der Daten entsprechend den
          gesetzlichen Vorgaben.
          <br />
          <br />
          Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die
          Vervollständigung der Sie betreffenden Daten oder die Berichtigung der
          Sie betreffenden unrichtigen Daten zu verlangen.
          <br />
          <br />
          Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu
          verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw.
          alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung
          der Verarbeitung der Daten zu verlangen.
          <br />
          <br />
          Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die
          Sie uns bereitgestellt haben nach Maßgabe der gesetzlichen Vorgaben zu
          erhalten und deren Übermittlung an andere Verantwortliche zu fordern.{' '}
          <br />
          <br />
          Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht,
          eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
          <br />
        </p>
        <h2 id="dsg-general-revokeconsent">Widerrufsrecht</h2>
        <p>
          Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die
          Zukunft zu widerrufen.
        </p>
        <h2 id="dsg-general-object">Widerspruchsrecht</h2>
        <p>
          <strong>
            Sie können der künftigen Verarbeitung der Sie betreffenden Daten
            nach Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der
            Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
            Direktwerbung erfolgen.
          </strong>
        </p>
        <h2 id="dsg-general-cookies">
          Cookies und Widerspruchsrecht bei Direktwerbung
        </h2>
        <p>
          Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der
          Nutzer gespeichert werden. Innerhalb der Cookies können
          unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär
          dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie
          gespeichert ist) während oder auch nach seinem Besuch innerhalb eines
          Onlineangebotes zu speichern. Als temporäre Cookies, bzw.
          „Session-Cookies“ oder „transiente Cookies“, werden Cookies
          bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot
          verlässt und seinen Browser schließt. In einem solchen Cookie kann
          z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein
          Login-Status gespeichert werden. Als „permanent“ oder „persistent“
          werden Cookies bezeichnet, die auch nach dem Schließen des Browsers
          gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden,
          wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in
          einem solchen Cookie die Interessen der Nutzer gespeichert werden, die
          für Reichweitenmessung oder Marketingzwecke verwendet werden. Als
          „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen
          Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt,
          angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht
          man von „First-Party Cookies“).
          <br />
          <br />
          Wir können temporäre und permanente Cookies einsetzen und klären
          hierüber im Rahmen unserer Datenschutzerklärung auf.
          <br />
          <br />
          Sofern wir die Nutzer um eine Einwilligung in den Einsatz von Cookies
          bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die
          Rechtsgrundlage dieser Verarbeitung Art. 6 Abs. 1 lit. a. DSGVO.
          Ansonsten werden die personenbezogenen Cookies der Nutzer entsprechend
          den nachfolgenden Erläuterungen im Rahmen dieser Datenschutzerklärung
          auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
          Analyse, Optimierung und wirtschaftlichem Betrieb unseres
          Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) oder sofern
          der Einsatz von Cookies zur Erbringung unserer vertragsbezogenen
          Leistungen erforderlich ist, gem. Art. 6 Abs. 1 lit. b. DSGVO, bzw.
          sofern der Einsatz von Cookies für die Wahrnehmung einer Aufgabe, die
          im öffentlichen Interesse liegt erforderlich ist oder in Ausübung
          öffentlicher Gewalt erfolgt, gem. Art. 6 Abs. 1 lit. e. DSGVO,
          verarbeitet.
          <br />
          <br />
          Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
          gespeichert werden, werden sie gebeten die entsprechende Option in den
          Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte
          Cookies können in den Systemeinstellungen des Browsers gelöscht
          werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen
          dieses Onlineangebotes führen.
          <br />
          <br />
          Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
          Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
          Dienste, vor allem im Fall des Trackings, über die US-amerikanische
          Seite{' '}
          <a href="http://www.aboutads.info/choices/">
            http://www.aboutads.info/choices/
          </a>{' '}
          oder die EU-Seite{' '}
          <a href="http://www.youronlinechoices.com/">
            http://www.youronlinechoices.com/
          </a>{' '}
          erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels
          deren Abschaltung in den Einstellungen des Browsers erreicht werden.
          Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
          dieses Onlineangebotes genutzt werden können.
        </p>
        <h2 id="dsg-general-erasure">Löschung von Daten</h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern
          nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben,
          werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre
          Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
          gesetzlichen Aufbewahrungspflichten entgegenstehen. <br />
          <br />
          Sofern die Daten nicht gelöscht werden, weil sie für andere und
          gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
          eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere
          Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
          steuerrechtlichen Gründen aufbewahrt werden müssen.
        </p>
        <h2 id="dsg-general-changes">
          Änderungen und Aktualisierungen der Datenschutzerklärung
        </h2>
        <p>
          Wir bitten Sie sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <p></p>
        <h2 id="dsg-commercialpurpose">Geschäftsbezogene Verarbeitung</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Zusätzlich verarbeiten wir
            <br />
            - Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit,
            Kundenkategorie).
            <br />
            - Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)
            <br />
            von unseren Kunden, Interessenten und Geschäftspartner zwecks
            Erbringung vertraglicher Leistungen, Service und Kundenpflege,
            Marketing, Werbung und Marktforschung.
          </span>
        </p>
        <p></p>
        <h2 id="dsg-services-onlineshop">
          Bestellabwicklung im Onlineshop und Kundenkonto
        </h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir verarbeiten die Daten unserer Kunden im Rahmen der
            Bestellvorgänge in unserem Onlineshop, um ihnen die Auswahl und die
            Bestellung der gewählten Produkte und Leistungen, sowie deren
            Bezahlung und Zustellung, bzw. Ausführung zu ermöglichen.
            <br />
            <br />
            Zu den verarbeiteten Daten gehören Bestandsdaten,
            Kommunikationsdaten, Vertragsdaten, Zahlungsdaten und zu den von der
            Verarbeitung betroffenen Personen gehören unsere Kunden,
            Interessenten und sonstige Geschäftspartner. Die Verarbeitung
            erfolgt zum Zweck der Erbringung von Vertragsleistungen im Rahmen
            des Betriebs eines Onlineshops, Abrechnung, Auslieferung und der
            Kundenservices. Hierbei setzen wir Session Cookies für die
            Speicherung des Warenkorb-Inhalts und permanente Cookies für die
            Speicherung des Login-Status ein.
            <br />
            <br />
            Die Verarbeitung erfolgt zur Erfüllung unserer Leistungen und
            Durchführung vertraglicher Maßnahmen (z.B. Durchführung von
            Bestellvorgängen) und soweit sie gesetzlich vorgeschrieben ist
            (z.B., gesetzlich erforderliche Archivierung von Geschäftsvorgängen
            zu Handels und Steuerzwecken). Dabei sind die als erforderlich
            gekennzeichneten Angaben zur Begründung und Erfüllung des Vertrages
            erforderlich. Die Daten offenbaren wir gegenüber Dritten nur im
            Rahmen der Auslieferung, Zahlung oder im Rahmen der gesetzlichen
            Erlaubnisse und Pflichten, als auch wenn dies auf Grundlage unserer
            berechtigten Interessen erfolgt, worüber wir Sie im Rahmen dieser
            Datenschutzerklärung informieren (z.B., gegenüber Rechts- und
            Steuerberatern, Finanzinstituten, Frachtunternehmen sowie Behörden).{' '}
            <br />
            <br />
            Nutzer können optional ein Nutzerkonto anlegen, indem sie
            insbesondere ihre Bestellungen einsehen können. Im Rahmen der
            Registrierung, werden die erforderlichen Pflichtangaben den Nutzern
            mitgeteilt. Die Nutzerkonten sind nicht öffentlich und können von
            Suchmaschinen nicht indexiert werden. Wenn Nutzer ihr Nutzerkonto
            gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto
            gelöscht, vorbehaltlich deren Aufbewahrung ist aus handels- oder
            steuerrechtlichen Gründen notwendig. Angaben im Kundenkonto
            verbleiben bis zu dessen Löschung mit anschließender Archivierung im
            Fall einer rechtlichen Verpflichtung oder unser berechtigten
            Interessen (z.B., im Fall von Rechtsstreitigkeiten). Es obliegt den
            Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu
            sichern.
            <br />
            <br />
            Im Rahmen der Registrierung und erneuter Anmeldungen sowie
            Inanspruchnahme unserer Onlinedienste, speichern wir die IP-Adresse
            und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung
            erfolgt auf Grundlage unserer berechtigten Interessen, als auch der
            Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung.
            Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht,
            außer sie ist zur Verfolgung unserer gesetzlichen Ansprüche als
            berechtigtes Interesse erforderlich oder es besteht hierzu eine
            gesetzliche Verpflichtung.
            <br />
            <br />
            Die Löschung erfolgt nach Ablauf gesetzlicher Gewährleistungs- und
            sonstiger vertraglicher Rechte oder Pflichten (z.B.,
            Zahlungsansprüche oder Leistungspflichten aus Verträgen mir Kunden),
            wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei
            Jahre überprüft wird; im Fall der Aufbewahrung aufgrund gesetzlicher
            Archivierungspflichten, erfolgt die Löschung insoweit nach deren
            Ablauf.
          </span>
        </p>
        <p></p>
        <h2 id="dsg-services-agency">Agenturdienstleistungen</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir verarbeiten die Daten unserer Kunden im Rahmen unserer
            vertraglichen Leistungen zu denen konzeptionelle und strategische
            Beratung, Kampagnenplanung, Software- und
            Designentwicklung/-beratung oder Pflege, Umsetzung von Kampagnen und
            Prozessen/ Handling, Serveradministration, Datenanalyse/
            Beratungsleistungen und Schulungsleistungen gehören.
            <br />
            <br />
            Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie
            Namen oder Adressen), Kontaktdaten (z.B., E-Mail, Telefonnummern),
            Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos),
            Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit), Zahlungsdaten
            (z.B., Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten
            (z.B. im Rahmen der Auswertung und Erfolgsmessung von
            Marketingmaßnahmen). Besondere Kategorien personenbezogener Daten
            verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile
            einer beauftragten Verarbeitung sind. Zu den Betroffenen gehören
            unsere Kunden, Interessenten sowie deren Kunden, Nutzer,
            Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der
            Verarbeitung besteht in der Erbringung von Vertragsleistungen,
            Abrechnung und unserem Kundenservice. Die Rechtsgrundlagen der
            Verarbeitung ergeben sich aus Art. 6 Abs. 1 lit. b DSGVO
            (vertragliche Leistungen), Art. 6 Abs. 1 lit. f DSGVO (Analyse,
            Statistik, Optimierung, Sicherheitsmaßnahmen). Wir verarbeiten
            Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen
            erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe
            hin. Eine Offenlegung an Externe erfolgt nur, wenn sie im Rahmen
            eines Auftrags erforderlich ist. Bei der Verarbeitung der uns im
            Rahmen eines Auftrags überlassenen Daten handeln wir entsprechend
            den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben einer
            Auftragsverarbeitung gem. Art. 28 DSGVO und verarbeiten die Daten zu
            keinen anderen, als den auftragsgemäßen Zwecken.
            <br />
            <br />
            Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
            vergleichbarer Pflichten. die Erforderlichkeit der Aufbewahrung der
            Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen
            Archivierungspflichten erfolgt die Löschung nach deren Ablauf (6 J,
            gem. § 257 Abs. 1 HGB, 10 J, gem. § 147 Abs. 1 AO). Im Fall von
            Daten, die uns gegenüber im Rahmen eines Auftrags durch den
            Auftraggeber offengelegt wurden, löschen wir die Daten entsprechend
            den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
          </span>
        </p>
        <p></p>
        <h2 id="dsg-services-payment">Externe Zahlungsdienstleister</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir setzen externe Zahlungsdienstleister ein, über deren Plattformen
            die Nutzer und wir Zahlungstransaktionen vornehmen können. Zu diesen
            Zahlungsdienstleistern können gehören, jeweils mit Link zur
            Datenschutzerklärung: Paypal (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
            >
              https://www.paypal.com/de/webapps/mpp/ua/privacy-full
            </a>
            ), Klarna (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.klarna.com/de/datenschutz/"
            >
              https://www.klarna.com/de/datenschutz/
            </a>
            ), Skrill (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/"
            >
              https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/
            </a>
            ), Giropay (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.giropay.de/rechtliches/datenschutz-agb/"
            >
              https://www.giropay.de/rechtliches/datenschutz-agb/
            </a>
            ), Visa (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.visa.de/datenschutz"
            >
              https://www.visa.de/datenschutz
            </a>
            ), Mastercard (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mastercard.de/de-de/datenschutz.html"
            >
              https://www.mastercard.de/de-de/datenschutz.html
            </a>
            ), American Express (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.americanexpress.com/de/content/privacy-policy-statement.html"
            >
              https://www.americanexpress.com/de/content/privacy-policy-statement.html
            </a>
            ), Stripe (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stripe.com/de/privacy"
            >
              https://stripe.com/de/privacy
            </a>
            ).
            <br />
            <br />
            Im Rahmen der Erfüllung von&nbsp;Verträgen setzen wir die
            Zahlungsdienstleister auf Grundlage des Art. 6 Abs. 1 lit. b. DSGVO
            ein. Im Übrigen setzen wir externe&nbsp;Zahlungsdienstleister auf
            Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f.
            DSGVO ein, um unseren Nutzern effektive und sichere
            Zahlungsmöglichkeit zu bieten.
            <br />
            <br />
            Zu den, durch die Zahlungsdienstleister verarbeiteten Daten gehören
            Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie
            z.B. Kontonummern oder Kreditkartennummern, Passwörter, TANs und
            Prüfsummen sowie die Vertrags-, Summen und empfängerbezogenen
            Angaben. Die Angaben sind erforderlich, um die Transaktionen
            durchzuführen. Die eingegebenen Daten werden jedoch nur durch die
            Zahlungsdienstleister verarbeitet und bei diesen gespeichert. D.h.
            wir erhalten keine konto- oder kreditkartenbezogenen Informationen,
            sondern lediglich Informationen mit Bestätigung oder
            Negativbeauskunftung der Zahlung.&nbsp;Unter Umständen werden die
            Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien
            übermittelt. Diese Übermittlung bezweckt die Identitäts- und
            Bonitätsprüfung. Hierzu verweisen wir auf die AGB und
            Datenschutzhinweise der&nbsp;Zahlungsdienstleister.
            <br />
            <br />
            Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
            Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche
            innerhalb der jeweiligen Webseiten, bzw. Transaktionsapplikationen
            abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer
            Informationen und Geltendmachung von Widerrufs-, Auskunfts- und
            anderen Betroffenenrechten.
          </span>
        </p>
        <p></p>
        <h2 id="dsg-administration">
          Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung
        </h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
            Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der
            gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei
            verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung
            unserer vertraglichen Leistungen verarbeiten. Die
            Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6
            Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden,
            Interessenten, Geschäftspartner und Websitebesucher betroffen. Der
            Zweck und unser Interesse an der Verarbeitung liegt in der
            Administration, Finanzbuchhaltung, Büroorganisation, Archivierung
            von Daten, also Aufgaben die der Aufrechterhaltung unserer
            Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und Erbringung
            unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf
            vertragliche Leistungen und die vertragliche Kommunikation
            entspricht den, bei diesen Verarbeitungstätigkeiten genannten
            Angaben.
            <br />
            <br />
            Wir offenbaren oder übermitteln hierbei Daten an die
            Finanzverwaltung, Berater, wie z.B., Steuerberater oder
            Wirtschaftsprüfer sowie weitere Gebührenstellen und
            Zahlungsdienstleister.
            <br />
            <br />
            Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen
            Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen
            Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese
            mehrheitlich unternehmensbezogenen Daten, speichern wir
            grundsätzlich dauerhaft.
            <br />
          </span>
        </p>
        <p></p>
        <h2 id="dsg-registration">Registrierfunktion</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung
            werden die erforderlichen Pflichtangaben den Nutzern mitgeteilt und
            auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO zu Zwecken der
            Bereitstellung des Nutzerkontos verarbeitet. Zu den verarbeiteten
            Daten gehören insbesondere die Login-Informationen (Name, Passwort
            sowie eine E-Mailadresse). Die im Rahmen der Registrierung
            eingegebenen Daten werden für die Zwecke der Nutzung des
            Nutzerkontos und dessen Zwecks verwendet. <br />
            <br />
            Die Nutzer können über Informationen, die für deren Nutzerkonto
            relevant sind, wie z.B. technische Änderungen, per E-Mail informiert
            werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren
            Daten im Hinblick auf das Nutzerkonto, vorbehaltlich einer
            gesetzlichen Aufbewahrungspflicht, gelöscht. Es obliegt den Nutzern,
            ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern.
            Wir sind berechtigt, sämtliche während der Vertragsdauer
            gespeicherten Daten des Nutzers unwiederbringlich zu löschen.
            <br />
            <br />
            Im Rahmen der Inanspruchnahme unserer Registrierungs- und
            Anmeldefunktionen sowie der Nutzung des Nutzerkontos, speichern wir
            die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
            Speicherung erfolgt auf Grundlage unserer berechtigten Interessen,
            als auch der Nutzer an Schutz vor Missbrauch und sonstiger
            unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
            grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche
            erforderlich oder es besteht hierzu besteht eine gesetzliche
            Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO. Die IP-Adressen
            werden spätestens nach 7 Tagen anonymisiert oder gelöscht.
            <br />
          </span>
        </p>
        <p></p>
        <h2 id="dsg-contact">Kontaktaufnahme</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
            Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur
            Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs.
            1 lit. b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen),
            Art. 6 Abs. 1 lit. f. (andere Anfragen) DSGVO verarbeitet.. Die
            Angaben der Nutzer können in einem Customer-Relationship-Management
            System ("CRM System") oder vergleichbarer Anfragenorganisation
            gespeichert werden.
            <br />
            <br />
            Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind.
            Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten
            die gesetzlichen Archivierungspflichten.
          </span>
        </p>
        <p></p>
        <h2 id="m391">Kommunikation via Messenger</h2>
        <p>
          Wir setzen zu Zwecken der Kommunikation Messenger-Dienste ein und
          bitten daher darum, die nachfolgenden Hinweise zur Funktionsfähigkeit
          der Messenger, zur Verschlüsselung, zur Nutzung der Metadaten der
          Kommunikation und zu Ihren Widerspruchsmöglichkeiten zu beachten.
        </p>
        <p>
          Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder
          E-Mail, kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten
          Kontaktmöglichkeiten oder die innerhalb unseres Onlineangebotes
          angegebenen Kontaktmöglichkeiten.
        </p>
        <p>
          Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der
          Inhalt Ihrer Nachricht und Anhänge) weisen wir darauf hin, dass die
          Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte
          Bilder) von Ende zu Ende verschlüsselt werden. Das bedeutet, dass der
          Inhalt der Nachrichten nicht einsehbar ist, nicht einmal durch die
          Messenger-Anbieter selbst. Sie sollten immer eine aktuelle Version der
          Messenger mit aktivierter Verschlüsselung nutzen, damit die
          Verschlüsselung der Nachrichteninhalte sichergestellt ist.
        </p>
        <p>
          Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf hin,
          dass die Anbieter der Messenger zwar nicht den Inhalt einsehen, aber
          in Erfahrung bringen können, dass und wann Kommunikationspartner mit
          uns kommunizieren sowie technische Informationen zum verwendeten Gerät
          der Kommunikationspartner und je nach Einstellungen ihres Gerätes auch
          Standortinformationen (sogenannte Metadaten) verarbeitet werden.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen: </strong> Sofern wir
          Kommunikationspartner vor der Kommunikation mit ihnen via Messenger um
          eine Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung
          ihrer Daten deren Einwilligung. Im Übrigen, falls wir nicht um eine
          Einwilligung bitten und sie z.B. von sich aus Kontakt mit uns
          aufnehmen, nutzen wir Messenger im Verhältnis zu unseren
          Vertragspartnern sowie im Rahmen der Vertragsanbahnung als eine
          vertragliche Maßnahme und im Fall anderer Interessenten und
          Kommunikationspartner auf Grundlage unserer berechtigten Interessen an
          einer schnellen und effizienten Kommunikation und Erfüllung der
          Bedürfnisse unser Kommunikationspartner an der Kommunikation via
          Messengern. Ferner weisen wir Sie darauf hin, dass wir die uns
          mitgeteilten Kontaktdaten ohne Ihre Einwilligung nicht erstmalig an
          die Messenger übermitteln.
        </p>
        <p>
          <strong>Widerruf, Widerspruch und Löschung:</strong> Sie können
          jederzeit eine erteilte Einwilligung widerrufen und der Kommunikation
          mit uns via Messenger jederzeit widersprechen. Im Fall der
          Kommunikation via Messenger löschen wir die Nachrichten entsprechend
          unseren generellen Löschrichtlinien (d.h. z.B., wie oben beschrieben,
          nach Ende vertraglicher Beziehungen, im Kontext von
          Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen
          können, etwaige Auskünfte der Kommunikationspartner beantwortet zu
          haben, wenn kein Rückbezug auf eine vorhergehende Konversation zu
          erwarten ist und der Löschung keine gesetzlichen
          Aufbewahrungspflichten entgegenstehen.
        </p>
        <p>
          <strong>
            Vorbehalt des Verweises auf andere Kommunikationswege:
          </strong>{' '}
          Zum Abschluss möchten wir darauf hinweisen, dass wir uns aus Gründen
          Ihrer Sicherheit vorbehalten, Anfragen über Messenger nicht zu
          beantworten. Das ist der Fall, wenn z.B. Vertragsinterna besonderer
          Geheimhaltung bedürfen oder eine Antwort über den Messenger den
          formellen Ansprüchen nicht genügt. In solchen Fällen verweisen wir Sie
          auf adäquatere Kommunikationswege.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
            Telefonnummern), Nutzungsdaten (z.B. besuchte Webseiten, Interesse
            an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
            Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
            Kommunikation, Direktmarketing (z.B. per E-Mail oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
            DSGVO).
          </li>
        </ul>
        <p>
          <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>WhatsApp:</strong> WhatsApp Messenger mit
            Ende-zu-Ende-Verschlüsselung; Dienstanbieter: WhatsApp Inc. WhatsApp
            Legal 1601 Willow Road Menlo Park, California 94025, USA; Website:{' '}
            <a
              href="https://www.whatsapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.whatsapp.com/
            </a>
            ; Datenschutzerklärung:{' '}
            <a
              href="https://www.whatsapp.com/legal"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.whatsapp.com/legal
            </a>
            ; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung
            von Daten in den USA):{' '}
            <a
              href="https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&amp;status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&amp;status=Active
            </a>
            .
          </li>
        </ul>
        <br />
        <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>
          Um unser Onlineangebot sicher und effizient bereitstellen zu können,
          nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern
          in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern)
          das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
          Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
          Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und
          technische Wartungsleistungen in Anspruch nehmen.
        </p>
        <p>
          Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten
          Daten können alle die Nutzer unseres Onlineangebotes betreffenden
          Angaben gehören, die im Rahmen der Nutzung und der Kommunikation
          anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist,
          um die Inhalte von Onlineangeboten an Browser ausliefern zu können,
          und alle innerhalb unseres Onlineangebotes oder von Webseiten
          getätigten Eingaben.
        </p>
        <p>
          <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in Anspruch
          genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den
          Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden
          die Adressen der Empfänger sowie Absender als auch weitere
          Informationen betreffend den E-Mailversand (z.B. die beteiligten
          Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die
          vorgenannten Daten können ferner zu Zwecken der Erkennung von SPAM
          verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im
          Internet grundsätzlich nicht verschlüsselt versendet werden. Im
          Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber
          (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren
          eingesetzt wird) nicht auf den Servern, von denen sie abgesendet und
          empfangen werden. Wir können daher für den Übertragungsweg der E-Mails
          zwischen dem Absender und dem Empfang auf unserem Server keine
          Verantwortung übernehmen.
        </p>
        <p>
          <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst
          (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den
          Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
          Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
          IP-Adressen und der anfragende Provider gehören.
        </p>
        <p>
          Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
          eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
          (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
          DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
          Stabilität sicherzustellen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
            Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <h2 id="dsg-hostingprovider">Hosting und E-Mail-Versand</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
            Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
            Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie
            technische Wartungsleistungen, die wir zum Zwecke des Betriebs
            dieses Onlineangebotes einsetzen. <br />
            <br />
            Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
            Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
            Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
            Onlineangebotes auf Grundlage unserer berechtigten Interessen an
            einer effizienten und sicheren Zurverfügungstellung dieses
            Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO
            (Abschluss Auftragsverarbeitungsvertrag).
          </span>
        </p>
        <p></p>
        <h2 id="dsg-logfiles">Erhebung von Zugriffsdaten und Logfiles</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
            berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
            Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst
            befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören
            Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs,
            übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp
            nebst Version, das Betriebssystem des Nutzers, Referrer URL (die
            zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
            <br />
            <br />
            Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
            Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer
            von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren
            weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
            endgültigen Klärung des jeweiligen Vorfalls von der Löschung
            ausgenommen.
          </span>
        </p>
        <p></p>
        <h2 id="dsg-socialmedia">Onlinepräsenzen in sozialen Medien</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
            Plattformen, um mit den dort aktiven Kunden, Interessenten und
            Nutzern kommunizieren und sie dort über unsere Leistungen
            informieren zu können.
            <br />
            <br />
            Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
            Raumes der Europäischen Union verarbeitet werden können. Hierdurch
            können sich für die Nutzer Risiken ergeben, weil so z.B. die
            Durchsetzung der Rechte der Nutzer erschwert werden könnte. Im
            Hinblick auf US-Anbieter die unter dem Privacy-Shield zertifiziert
            sind, weisen wir darauf hin, dass sie sich damit verpflichten, die
            Datenschutzstandards der EU einzuhalten.
            <br />
            <br />
            Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs-
            und Werbezwecke verarbeitet. So können z.B. aus dem
            Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer
            Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum
            verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der
            Plattformen zu schalten, die mutmaßlich den Interessen der Nutzer
            entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den
            Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und
            die Interessen der Nutzer gespeichert werden. Ferner können in den
            Nutzungsprofilen auch Daten unabhängig der von den Nutzern
            verwendeten Geräte gespeichert werden (insbesondere wenn die Nutzer
            Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt
            sind).
            <br />
            <br />
            Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf
            Grundlage unserer berechtigten Interessen an einer effektiven
            Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6
            Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern
            der Plattformen um eine Einwilligung in die vorbeschriebene
            Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der
            Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
            <br />
            <br />
            Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und
            der Widerspruchsmöglichkeiten (Opt-Out), verweisen wir auf die
            nachfolgend verlinkten Angaben der Anbieter.
            <br />
            <br />
            Auch im Fall von Auskunftsanfragen und der Geltendmachung von
            Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten bei
            den Anbietern geltend gemacht werden können. Nur die Anbieter haben
            jeweils Zugriff auf die Daten der Nutzer und können direkt
            entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
            dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
            <br />
            <br />- Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand
            Canal Square, Grand Canal Harbour, Dublin 2, Irland) auf Grundlage
            einer{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/legal/terms/page_controller_addendum"
            >
              Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten
            </a>{' '}
            - Datenschutzerklärung:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/about/privacy/"
            >
              https://www.facebook.com/about/privacy/
            </a>
            , speziell für Seiten:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
            >
              https://www.facebook.com/legal/terms/information_about_page_insights_data
            </a>{' '}
            , Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/settings?tab=ads"
            >
              https://www.facebook.com/settings?tab=ads
            </a>{' '}
            und{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.youronlinechoices.com"
            >
              http://www.youronlinechoices.com
            </a>
            , Privacy Shield:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
            </a>
            .<br />
            <br />- Google/ YouTube (Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland) – Datenschutzerklärung: &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://policies.google.com/privacy"
            >
              https://policies.google.com/privacy
            </a>
            , Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://adssettings.google.com/authenticated"
            >
              https://adssettings.google.com/authenticated
            </a>
            , Privacy Shield:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
            >
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            .<br />
            <br />- Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA,
            94025, USA) – Datenschutzerklärung/ Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://instagram.com/about/legal/privacy/"
            >
              http://instagram.com/about/legal/privacy/
            </a>
            .<br />
            <br />- Twitter (Twitter Inc., 1355 Market Street, Suite 900, San
            Francisco, CA 94103, USA) - Datenschutzerklärung:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/de/privacy"
            >
              https://twitter.com/de/privacy
            </a>
            , Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/personalization"
            >
              https://twitter.com/personalization
            </a>
            , Privacy Shield:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
            </a>
            .<br />
            <br />- Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA,
            94301, USA) – Datenschutzerklärung/ Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://about.pinterest.com/de/privacy-policy"
            >
              https://about.pinterest.com/de/privacy-policy
            </a>
            .<br />
            <br />- LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place,
            Dublin 2, Irland) - Datenschutzerklärung{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/legal/privacy-policy"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>{' '}
            , Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            , Privacy Shield:&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active"
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active
            </a>
            .<br />
            <br />- Xing (XING AG, Dammtorstraße 29-32, 20354 Hamburg,
            Deutschland) - Datenschutzerklärung/ Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://privacy.xing.com/de/datenschutzerklaerung"
            >
              https://privacy.xing.com/de/datenschutzerklaerung
            </a>
            .<br />
            <br />- Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3
            4PR, United Kingdom) - Datenschutzerklärung/ Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wakelet.com/privacy.html"
            >
              https://wakelet.com/privacy.html
            </a>
            .<br />
            <br />- Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77,
            10115 Berlin, Deutschland) - Datenschutzerklärung/ Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://soundcloud.com/pages/privacy"
            >
              https://soundcloud.com/pages/privacy
            </a>
            .
          </span>
        </p>
        <p></p>
        <h2 id="dsg-thirdparty-einleitung">
          Einbindung von Diensten und Inhalten Dritter
        </h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
            und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des
            Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von
            Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos
            oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet
            als “Inhalte”). <br />
            <br />
            Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
            IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die
            Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist
            damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen
            uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
            IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
            Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare
            Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
            Marketingzwecke verwenden. Durch die "Pixel-Tags" können
            Informationen, wie der Besucherverkehr auf den Seiten dieser Website
            ausgewertet werden. Die pseudonymen Informationen können ferner in
            Cookies auf dem Gerät der Nutzer gespeichert werden und unter
            anderem technische Informationen zum Browser und Betriebssystem,
            verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
            unseres Onlineangebotes enthalten, als auch mit solchen
            Informationen aus anderen Quellen verbunden werden.
          </span>
        </p>
        <p></p>
        <h2 id="dsg-thirdparty-youtube">Youtube</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir binden die Videos der Plattform “YouTube” des Anbieters Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
            Datenschutzerklärung:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/policies/privacy/"
            >
              https://www.google.com/policies/privacy/
            </a>
            , Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://adssettings.google.com/authenticated"
            >
              https://adssettings.google.com/authenticated
            </a>
            .
          </span>
        </p>
        <p></p>
        <h2 id="dsg-thirdparty-googlefonts">Google Fonts</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir binden die Schriftarten ("Google Fonts") des Anbieters Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
            Nach Angaben von Google werden die Daten der Nutzer allein zu
            Zwecken der Darstellung der Schriftarten im Browser der Nutzer
            verwendet. Die Einbindung erfolgt auf Grundlage unserer berechtigten
            Interessen an einer technisch sicheren, wartungsfreien und
            effizienten Nutzung von Schriftarten, deren einheitlicher
            Darstellung sowie Berücksichtigung möglicher lizenzrechtlicher
            Restriktionen für deren Einbindung. Datenschutzerklärung:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/policies/privacy/"
            >
              https://www.google.com/policies/privacy/
            </a>
            .
          </span>
        </p>
        <p></p>
        <h2 id="dsg-thirdparty-googlerecaptcha">Google ReCaptcha</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir binden die Funktion zur Erkennung von Bots, z.B. bei Eingaben in
            Onlineformularen ("ReCaptcha") des Anbieters GGoogle Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
            Datenschutzerklärung:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/policies/privacy/"
            >
              https://www.google.com/policies/privacy/
            </a>
            , Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://adssettings.google.com/authenticated"
            >
              https://adssettings.google.com/authenticated
            </a>
            .
          </span>
        </p>
        <p></p>
        <h2 id="dsg-thirdparty-googlemaps">Google Maps</h2>
        <p></p>
        <p>
          <span className="ts-muster-content">
            Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters
            Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Irland, ein. Zu den verarbeiteten Daten können insbesondere
            IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht
            ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen
            ihrer Mobilgeräte vollzogen), erhoben werden. Die Daten können in
            den USA verarbeitet werden. Datenschutzerklärung:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/policies/privacy/"
            >
              https://www.google.com/policies/privacy/
            </a>
            , Opt-Out:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://adssettings.google.com/authenticated"
            >
              https://adssettings.google.com/authenticated
            </a>
            .
          </span>
        </p>
        <a
          href="https://datenschutz-generator.de"
          className="dsg1-6"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke
        </a>
      </Card>
    </Section>
  </BaseLayout>
)

export const Head = () => <title>{makeSeoTitle('Datenschutzerklärung')}</title>

export default PrivacyPolicyPage
